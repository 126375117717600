import type { GetStaticProps } from "next"

import { gql } from "@urql/core"
import invariant from "tiny-invariant"

import { CORE_PAGE_FIELDS, GET_GLOBAL_FIELDS, WP_MEDIA_FIELDS } from "@/graphql-queries"
import { GQLGlobalFields, GQLPageNotFoundFields } from "@/models"
import client from "@/urql-client"
import { Hero } from "@components/hero"
import { Layout } from "@components/layout"

import styles from "./404.module.scss"

const GET_PAGE_NOT_FOUND = gql`
  ${WP_MEDIA_FIELDS}
  ${CORE_PAGE_FIELDS}
  query GetPageNotFoundFields($id: ID!) {
    page(id: $id, idType: DATABASE_ID) {
      ...CorePageFields
    }
  }
`

export const getStaticProps: GetStaticProps<Bosam404Props> = async () => {
  // ensure necessary env vars exist
  invariant(process.env.WP_PAGEID_PAGENOTFOUND, "WP_PAGEID_PAGENOTFOUND is undefined")

  const [{ data: globalFields }, { data: pageNotFound }] = await Promise.all([
    client.query<GQLGlobalFields>(GET_GLOBAL_FIELDS, {}).toPromise(),
    client
      .query<GQLPageNotFoundFields>(GET_PAGE_NOT_FOUND, { id: process.env.WP_PAGEID_PAGENOTFOUND })
      .toPromise(),
  ])

  // ensure necessary data exist
  invariant(globalFields, "globalFields is undefined")
  invariant(pageNotFound, "pageNotFound is undefined")

  return {
    props: { globalFields, pageNotFound },
    revalidate: 24 * 60 * 60, // 1 day
  }
}

interface Bosam404Props {
  globalFields: GQLGlobalFields
  pageNotFound: GQLPageNotFoundFields
}

export default function Bosam404({ globalFields, pageNotFound }: Bosam404Props) {
  const { seo, featuredImage, acfHero } = pageNotFound.page

  return (
    <Layout seo={seo} globalFields={globalFields}>
      <div className={styles.section}>
        <Hero featuredImage={featuredImage.node} hero={acfHero} links={acfHero.links} loose />
      </div>
    </Layout>
  )
}
